<template>
  <div>
    <player></player>
  </div>
</template>

<script>
import Player from "@/views/live/Player";

export default {
  name: "Live",
  components: {
    Player
  }
}

</script>

<style scoped>

</style>
